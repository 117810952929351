<template>
    <div class="mb-20 overflow-hidden">
        <component
            :is="model.link.linkType !== LinkType.External ? 'router-link' : 'a'"
            v-if="model.link && model.link?.url"
            class="cursor-pointer"
            :to="model.link?.url"
            :href="model.link.linkType !== LinkType.External ? undefined : model.link?.url"
            :target="model.link.target"
            @click="onClick">
            <ResponsiveImage
                v-if="isMediumOrGreaterScreen && model.image"
                :image-url="model.image.url"
                :aspect-ratio="6 / 1"/>
            <ResponsiveImage
                v-else-if="model.mobileImage"
                :image-url="model.mobileImage.url"
                :aspect-ratio="4 / 3"/>
        </component>
    </div>
</template>

<script setup lang="ts">
import { useBreakpoints } from '@/core/responsive/breakpoints/breakpoints.composable';
import { CategoryBannerBlockModel, LinkType } from '@/api/cms/';
import { trackPromotionClick } from '@/project/tracking/tracking.service';

const props = defineProps<{
    model: CategoryBannerBlockModel;
    columnIndex?: number
    totalColumns?: number
    indexInColumn: number
    compact?: boolean
}>();

let index = 0;
if (props.columnIndex !== undefined && props.totalColumns) {
    index = props.indexInColumn * props.totalColumns + props.columnIndex;
}

function onClick() {
    trackPromotionClick([{
        nameOfModule: 'CategoryBannerBlock',
        headlineOrImageName: props.model.image?.name ?? '',
        creative: props.model.link.name,
        index,
    }]);
}

const breakpoints = useBreakpoints();
const isMediumOrGreaterScreen = breakpoints.greater('md');
</script>
